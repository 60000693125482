import { makeObservable, observable, action } from 'mobx';

class TranslationStore {

    trnsl = {
        user_lang: 'en',
        en: {
            home: 'Home',
            tasks: 'Tasks',
            mine: 'ByBit',
            frends: 'Frends',
            at_you: 'At you',
            scrolling: 'scrolling',
            beggar: 'Beggar',
            janitor: 'Janitor',
            worker: 'Worker',
            engineer: 'Engineer',
            it_technician: 'It technician',
            cryptan: 'Cryptan',
            top_Manager: 'Top Manager',
            seo: 'SEO',
            major: 'Major',
            banker: 'Banker',
            president: 'President',
            you_balance: 'Your balance',
            do_it: 'Complete tasks and get extra spins and tokens!',
            subscribe: 'Subscribe',
            bybitref_link: 'Registration on ByBit',
            passed: 'Passed',
            check: 'Check',
            referals: 'Referals',
            bonus: 'Invite a friend and you and your friend will receive bonuses!',
            invite_link: 'My invite link',
            my_referals: 'My referals',
            line: 'line',
            invite: 'Invite',
            no_referals: 'You dont have referals yet',
            invite_text: 'Play with me and earn tokens.',
            invite_frends: 'Invite friends',
            coming_soon: 'Coming soon',
            support_a_friend: 'Gift for friend',
            support_introtext: 'Send a referral link and choose a gift for your friend. Boost your karma!',
            support_create: 'Create',
            support_gift: 'Gift',
            support_gifts: 'Gifts',
            support_copy_link: 'The link has been copied!',
            is_not_balance: 'There is not enough balance',
            support_link_already_exists: 'Error! The link has already been created or you dont have enough balance',
            weblink: 'Follow the link',
            wait: 'Wait',
            airdrop_wallet: "Your airdrop wallet",
            wallet_is_connected: "The wallet is connected",
            wallet_is_not_connected: "The wallet is not connected",
        },
        ru: {
            home: 'Главная',
            tasks: 'Задания',
            mine: 'ByBit',
            frends: 'Друзья',
            at_you: 'У Вас',
            scrolling: 'прокрутов',
            beggar: 'Beggar',
            janitor: 'Janitor',
            worker: 'Worker',
            engineer: 'Engineer',
            it_technician: 'It technician',
            cryptan: 'Cryptan',
            top_Manager: 'Top Manager',
            seo: 'SEO',
            major: 'Major',
            banker: 'Banker',
            president: 'President',
            you_balance: 'Ваш баланс',
            do_it: 'Выполняй задания и получай дополнительные спины и токены!',
            subscribe: 'Подписаться',
            bybitref_link: 'Регистрация на ByBit',
            passed: 'Пройден',
            check: 'Проверить',
            referals: 'Рефералы',
            bonus: 'Пригласи друга и вы и ваш друг получите бонусы!',
            invite_link: 'Моя реф. ссылка',
            my_referals: 'Мои рефералы',
            line: 'линия',
            invite: 'Пригласить',
            no_referals: 'У Вас ещё нет рефералов',
            invite_text: 'Играй со мной, и зарабатывай токены.',
            invite_frends: 'Пригласи друзей',
            coming_soon: 'Скоро будет',
            support_a_friend: 'Поддержи друга',
            support_introtext: 'Отправь реферальную ссылку и выбери подарок своему другу. Прокачай свою карму!',
            support_create: 'Создать',
            support_gift: 'Подарок',
            support_gifts: 'Подарки',
            support_copy_link: 'Ссылка скопирована!',
            is_not_balance: 'Не хватает монет на балансе',
            support_link_already_exists: 'Ошибка! Ссылка уже создана или у Вас не хватает баланса',
            weblink: 'Перейти по ссылке',
            wait: 'Ждите',
            airdrop_wallet: "Твой кошелек для аирдропа",
            wallet_is_connected: "Кошелёк подключен!",
            wallet_is_not_connected: "Кошелёк не подключен!",
        },
        es: {
            home: 'Inicio',
            tasks: 'tasks',
            mine: 'ByBit',
            frends: 'Amigos',
            at_you: 'Tú',
            scrolling: 'desplazamiento',
            beggar: 'Beggar',
            janitor: 'Janitor',
            worker: 'Worker',
            engineer: 'Engineer',
            it_technician: 'It technician',
            cryptan: 'Cryptan',
            top_Manager: 'Top Manager',
            seo: 'SEO',
            major: 'Major',
            banker: 'Banker',
            president: 'President',
            you_balance: 'Tu saldo',
            do_it: '¡Completa tareas y consigue giros y fichas extra!',
            subscribe: 'Suscríbete',
            bybitref_link: 'Regístrate en ByBit',
            passed: 'Aprobado',
            check: 'Comprobar',
            referals: 'Referidos',
            bonus: 'Invita a un amigo y tú y tu amigo recibiréis bonus!',
            invite_link: 'Mi enlace de referidos',
            my_referals: 'Mis referidos',
            line: 'Línea',
            invite: 'Invitar',
            no_referals: 'Aún no tienes referidos',
            invite_text: 'Juega conmigo y gana tokens.',
            invite_frends: 'Invita a tus amigos',
            coming_soon: 'Próximamente',
            support_a_friend: 'Apoya a un amigo',
            support_introtext: 'Envía un enlace de recomendación y elige un regalo para tu amigo. Aumenta tu karma!',
            support_create: 'Crear',
            support_gift: 'Regalo',
            support_gifts: 'Regalos',
            support_copy_link: 'Enlace copiado!',
            is_not_balance: 'No hay suficientes monedas en el saldo',
            support_link_already_exists: 'Error! El enlace ya ha sido creado o no tienes suficiente saldo',
            weblink: 'Sigue el enlace',
            wait: 'Espera',
            airdrop_wallet: "Tu monedero airdrop",
            wallet_is_connected: "Cartera conectada",
            wallet_is_not_connected: "Cartera no conectada",
        }
    };


    constructor() {
        makeObservable(this, {
            trnsl: observable,
            setTranslations: action
        });
    }

    setTranslations(lang, trnsl) {
        this.trnsl[lang] = trnsl;
    }
}

const translationStore = new TranslationStore();
export default translationStore;