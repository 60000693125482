import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { observer } from "mobx-react-lite";

import { TonConnectButton, useTonAddress, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';

//Конфиг
import config from '../config';

//Стили
import '../css/iduser.css';
import '../css/referal_links.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';


//Компоненты
import TopHeader from '../components/TopHeader';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Iduser = observer(() => {

    const wallet = useTonWallet();//текущий кошелёк пользователя

    //Языки / переводы
    const tr = translationStore.trnsl;


    ////Для кошелька
    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);

    const linkRef = useRef(null); //Ссылка на инпут для копирования
    const linkTwoRef = useRef(null); //Ссылка на инпут для копирования
    const copyToClipboard = (lRef) => {
        if (lRef.current) {
            lRef.current.select();
            document.execCommand('copy');
            toast.success(tr[tr.user_lang].support_copy_link, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
        }
    };

    const [isConnected, setIsConnected] = useState(false);
    const tonConnectUI = useTonConnectUI();

    useEffect(() => {
        // Проверка статуса подключения
        if (tonConnectUI.account) {
            setIsConnected(true); // Пользователь подключен
            toast.success("Вы успешно подключили кошелёк", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
            });
            console.log('tonConnectUI.account --->', tonConnectUI.account);
        } else {
            setIsConnected(false); // Кошелек не подключен
            //toast.info("Кошелёк отключён!", {
            //    position: "bottom-center",
            //    autoClose: 3000,
            //    hideProgressBar: false,
            //    closeOnClick: true,
            //    pauseOnHover: true,
            //    draggable: true,
            //    progress: undefined,
            //    theme: "dark",
            //    transition: Slide,
            //});
        }
    }, [tonConnectUI.account]); // Следим за изменением аккаунта

    //Функция для записи тон кошелька
    const saveTonUser = () => {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/usertonsave';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            "idchat": PlayerStore.player.idchat,
            "friendly_address": userFriendlyAddress,
            "raw_address": rawAddress
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('Кошелёк сохранён!: ', JSON.stringify(response.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка  2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }

    useEffect(() => {
        saveTonUser();
    }, [tonConnectUI.account, userFriendlyAddress]); // Следим за изменением аккаунта


    return (
        <>
            <div className='slot_machine_flex_top'>
                <TopHeader />
            </div>
            {/*
            <div className='iduser_main'>
                <img style={{ width: 50, height: 40 }} src="/images/moneta.png" alt="" />
                <div style={{ fontSize: 28, fontWeight: 'bold' }}>COMING SOON</div>
            </div>
            */}

            <div className='iduser_main'>
                <div className='iduser_mainwrap'>
                    <img src="/images/moneta.png" style={{ width: '80px', height: '60px' }} />

                    <div className="iduser_title">
                        {tr[tr.user_lang].airdrop_wallet}
                    </div>

                    <TonConnectButton className="iduser_button_ton" />

                    <div>
                        {userFriendlyAddress ? tr[tr.user_lang].wallet_is_connected : tr[tr.user_lang].wallet_is_not_connected}
                    </div>




                    {userFriendlyAddress && (
                        <div className='iduser_ton'>
                            <div className='iduser_ton_address'>
                                <div className='iduser_ton_lable'>User-friendly address: </div>
                                <div className='referal_link_form'>

                                    <div className='referal_link_row' style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            ref={linkRef}
                                            value={userFriendlyAddress}
                                            readOnly
                                            className='referal_link_input'
                                        />
                                        <button
                                            onClick={() => copyToClipboard(linkRef)}
                                            className='referal_link_copybutton'
                                        >
                                            <img src='/images/icon-copy.svg' className='icon-copy' />
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div className='iduser_ton_address'>
                                <div className='iduser_ton_lable'>Raw address:</div>
                                <div className='referal_link_row' style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        ref={linkTwoRef}
                                        value={rawAddress}
                                        readOnly
                                        className='referal_link_input'
                                    />
                                    <button
                                        onClick={() => copyToClipboard(linkTwoRef)}
                                        className='referal_link_copybutton'
                                    >
                                        <img src='/images/icon-copy.svg' className='icon-copy' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>

            <ToastContainer />
        </>
    );
})

export default Iduser;
